import { Action } from 'redux';

import { camelizeErrorSource, extractResponseErrors } from 'api/errors';

import { CANCEL_ACTIVITY, START_ACTIVITY } from 'store/modules/activity';

import { containsUuid } from 'utils/string';

// Actions
//======================================================================================================================
export const FORBIDDEN = 'FORBIDDEN';
export const METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED';
export const UNPROCESSABLE_CONTENT = 'UNPROCESSABLE_CONTENT';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const CONTENT_TOO_LARGE = 'CONTENT_TOO_LARGE';
export const UNHANDLED_ERROR = 'UNHANDLED_ERROR';

export const FORM_HTTP_ERRORS = [FORBIDDEN, UNPROCESSABLE_CONTENT];

export const RESET_ERROR = 'RESET_ERROR';

// Action Creators
//======================================================================================================================

export function forbidden({
  failureType,
  data,
}: {
  failureType: string;
  data?: { errors?: ErrorT[]; error?: ErrorT };
}): NetworkErrorResponse {
  return {
    type: FORBIDDEN,
    payload: { failureType, errors: extractResponseErrors(data).map(camelizeErrorSource) },
    error: true,
  };
}

export function methodNotAllowed({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: METHOD_NOT_ALLOWED,
    payload: { failureType },
    error: true,
  };
}

export function unprocessableContent({
  failureType,
  data,
}: {
  failureType: string;
  data?: { errors?: ErrorT[]; error?: ErrorT };
}): NetworkErrorResponse {
  return {
    type: UNPROCESSABLE_CONTENT,
    payload: { failureType, errors: extractResponseErrors(data).map(camelizeErrorSource) },
    error: true,
  };
}

export function internalServerError({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: INTERNAL_SERVER_ERROR,
    payload: { failureType },
    error: true,
  };
}

export function notFound({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: NOT_FOUND,
    payload: { failureType },
    error: true,
  };
}

export function contentTooLarge({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: CONTENT_TOO_LARGE,
    payload: { failureType },
    error: true,
  };
}

export function unhandledError({ failureType, data }: { failureType: string; data?: any }): NetworkErrorResponse {
  return {
    type: UNHANDLED_ERROR,
    payload: { failureType, errors: extractResponseErrors(data).map(camelizeErrorSource) },
    error: true,
  };
}

export function resetError() {
  return { type: RESET_ERROR };
}

// Reducer
//======================================================================================================================

type ErrorsState = ErrorT[];

type ErrorsAction = Action<string> &
  (
    | { payload: { failureType: string; errors: ErrorT[] }; error: boolean }
    | { payload: ErrorT[]; error: boolean }
    | { payload: ErrorT; error: boolean }
    | { payload: null; error: boolean }
  );

export const initialState = [];

function createSetErrorsReducer({
  handlerType = 'unhandled',
  errorMapFunction,
}: {
  handlerType?: ErrorHandlerType;
  errorMapFunction?: (rawError: ErrorT) => ErrorT;
}) {
  const reducer = (_state: ErrorsState, action: ErrorsAction): ErrorsState => {
    const { payload, error: showError } = action;
    const failureType = payload && 'failureType' in payload ? payload.failureType : undefined;

    if (!payload || !showError) return [];

    const errors = Array.isArray(payload) ? payload : 'errors' in payload ? payload.errors : [payload];
    const mappedErrors = errors.map(errorMapFunction || ((rawError) => ({ ...rawError, handlerType, failureType })));

    return mappedErrors;
  };

  return reducer;
}

const FORM_HANDLER_ACTIONS = new Set<string>();
const UUID_FORM_HANDLER_ACTIONS = new Set<string>();

export function registerFormErrorAction<T extends string>(action: T): T {
  FORM_HANDLER_ACTIONS.add(action);

  return action;
}

export function registerUuidFormErrorAction<T extends string>(action: T): T {
  UUID_FORM_HANDLER_ACTIONS.add(action);

  return action;
}

export function errorsReducer(state: ErrorsState = initialState, action: ErrorsAction): ErrorsState {
  const { type, payload } = action;
  const failureType = payload && 'failureType' in payload ? payload.failureType : undefined;

  switch (true) {
    case FORM_HTTP_ERRORS.includes(type) && failureType && FORM_HANDLER_ACTIONS.has(failureType): {
      const reducer = createSetErrorsReducer({ handlerType: 'form' });

      return reducer(state, action);
    }

    case FORM_HTTP_ERRORS.includes(type) && failureType && UUID_FORM_HANDLER_ACTIONS.has(failureType): {
      const errorMapFunction = (rawError: ErrorT): ErrorT => {
        if (!rawError) return { handlerType: 'form' };

        return {
          ...rawError,
          source: rawError.source && containsUuid(rawError.source) ? `identifiers.${rawError.source}` : rawError.source,
          handlerType: 'form',
        };
      };
      const reducer = createSetErrorsReducer({ errorMapFunction });

      return reducer(state, action);
    }

    case type === FORBIDDEN: {
      const reducer = createSetErrorsReducer({ handlerType: 'http_forbidden' });

      return reducer(state, action);
    }

    case type === NOT_FOUND: {
      const reducer = createSetErrorsReducer({ handlerType: 'http_not_found' });

      return reducer(state, action);
    }

    case type === UNPROCESSABLE_CONTENT: {
      const reducer = createSetErrorsReducer({ handlerType: 'http_unprocessable_content' });

      return reducer(state, action);
    }

    case type === METHOD_NOT_ALLOWED: {
      const reducer = createSetErrorsReducer({ handlerType: 'http_method_not_allowed' });

      return reducer(state, action);
    }

    case type === CONTENT_TOO_LARGE: {
      const reducer = createSetErrorsReducer({ handlerType: 'http_content_too_large' });

      return reducer(state, action);
    }

    case type === INTERNAL_SERVER_ERROR:
    case type === UNHANDLED_ERROR: {
      const reducer = createSetErrorsReducer({});

      return reducer(state, action);
    }

    case type === RESET_ERROR: {
      return initialState;
    }

    case type === CANCEL_ACTIVITY:
    case type === START_ACTIVITY: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
