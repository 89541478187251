import range from 'lodash/range';

// Constants
//======================================================================================================================

export const SECOND_IN_MILLIS = 1_000;
export const MINUTE_IN_MILLIS = 60 * SECOND_IN_MILLIS;
export const HOUR_IN_MILLIS = 60 * MINUTE_IN_MILLIS;
export const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;

export const START_OF_EXISTENCE = 2015;

// Checks
//======================================================================================================================

export function isPastDay(d: Date) {
  return isDayBefore(d, today());
}

export function isFutureDay(d: Date) {
  return isDayAfter(d, today());
}

export function isToday(d: Date): boolean {
  return isSameDay(today(), d);
}

export function isIsoDate(str: string) {
  // https://www.w3.org/TR/NOTE-datetime
  const isoDateRegExp = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d)?(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)$/;

  return isoDateRegExp.test(str);
}

export function isMidday(d: Date) {
  return d.getTime() === midday(d).getTime();
}

export function isStartOfDay(d: Date) {
  return d.getTime() === startOfDay(d).getTime();
}

export function isEndOfDay(d: Date) {
  return d.getTime() === endOfDay(d).getTime();
}

// Compare
//======================================================================================================================

export function isSameYear(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getFullYear() === d2.getFullYear();
}

export function isSameMonth(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getMonth() === d2.getMonth() && isSameYear(d1, d2);
}

export function isSameDay(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getDate() === d2.getDate() && isSameMonth(d1, d2);
}

export function isSameTime(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getTime() === d2.getTime();
}

export function isDayBefore(d1: Date, d2: Date) {
  const day1 = cloneDate(d1).setHours(0, 0, 0, 0);
  const day2 = cloneDate(d2).setHours(0, 0, 0, 0);

  return day1 < day2;
}

export function isDayAfter(d1: Date, d2: Date) {
  const day1 = cloneDate(d1).setHours(0, 0, 0, 0);
  const day2 = cloneDate(d2).setHours(0, 0, 0, 0);

  return day1 > day2;
}

export function isEarlierThan(d1: Date, d2: Date) {
  return d1.getTime() < d2.getTime();
}

// Modifiers
//======================================================================================================================

export function midday(d: Date): Date {
  const clone = cloneDate(d);
  clone.setHours(12, 0, 0, 0);

  return clone;
}

export function startOfDay(d: Date) {
  const clone = cloneDate(d);
  clone.setHours(0, 0, 0, 0);

  return clone;
}

export function endOfDay(d: Date) {
  const clone = cloneDate(d);
  clone.setDate(clone.getDate() + 1);
  clone.setHours(0, 0, 0, -1);

  return clone;
}

export function cloneDate(d: Date): Date {
  return new Date(d.getTime());
}

export function addMilliseconds(d: Date, millis: number): Date {
  const clone = cloneDate(d);
  clone.setTime(d.getTime() + millis);

  return clone;
}

export const addMinutes = (d: Date, minutes: number): Date => {
  return addMilliseconds(d, minutes * MINUTE_IN_MILLIS);
};

export const addHours = (d: Date, hours: number): Date => {
  return addMilliseconds(d, hours * HOUR_IN_MILLIS);
};

export const addDays = (d: Date, days: number): Date => {
  return addMilliseconds(d, days * DAY_IN_MILLIS);
};

export function shiftMonth(date: Date, amountOfMonths: number): Date {
  const clone = cloneDate(date);
  clone.setDate(1);
  clone.setMonth(clone.getMonth() + amountOfMonths);

  return clone;
}

export function shiftWeek(date: Date, amountOfWeeks: number): Date {
  const clone = cloneDate(date);
  clone.setDate(clone.getDate() + amountOfWeeks * 7);

  return clone;
}

// Getters
//======================================================================================================================

export function today(): Date {
  return midday(new Date());
}

export function yesterday(): Date {
  return addDays(today(), -1);
}

export function getWeekNumber(d?: Date): number {
  // Create a copy of this date object
  const target = d ? cloneDate(d) : new Date();

  // ISO week date weeks start on monday
  // so correct the day number
  const dayNr = (target.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week
  // with the first thursday of that year.
  // Set the target date to the thursday in the target week
  target.setDate(target.getDate() - dayNr + 3);

  // Store the millisecond value of the target date
  const firstThursday = target.valueOf();

  // Set the target to the first thursday of the year
  // First set the target to january first
  target.setMonth(0, 1);
  // Not a thursday? Correct the date to the next thursday
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }

  const targetMs = target.getTime();

  // The weeknumber is the number of weeks between the
  // first thursday of the year and the thursday in the target week

  return 1 + Math.ceil((firstThursday - targetMs) / (7 * DAY_IN_MILLIS));
}

export function getFirstDayOfWeek(weekDayOffset: WeekDay, d?: Date): Date {
  const date = d ? midday(d) : today();
  const dayNr = date.getDay();
  const offset = (dayNr - weekDayOffset + 7) % 7;

  date.setDate(date.getDate() - offset);

  return date;
}

// Parse
//======================================================================================================================

export function stringToDate(value: string, locale: string): Date | null {
  const separator = '[^\\d]+';
  const regexParts = {
    Y: '(\\d{4})',
    M: '(\\d{1,2})',
    D: '(\\d{1,2})',
  };
  const dateFormat = getDateFormat(locale);
  const dateRegex = new RegExp(dateFormat.map((p) => regexParts[p]).join(separator));

  const match = value.match(dateRegex);

  if (!match) return null;

  const year = parseInt(match[dateFormat.indexOf('Y') + 1], 10);
  const month = parseInt(match[dateFormat.indexOf('M') + 1], 10);
  const day = parseInt(match[dateFormat.indexOf('D') + 1], 10);

  if (month < 1 || month > 12) return null;
  if (day < 1 || day > 31) return null;

  return midday(new Date(year, month - 1, day));
}

export function stringToTime(value: string): Date | null {
  const timeRegex = /^(0[0-9]|[0-9]|1[0-9]|2[0-3])[^\d]+(0[0-9]|[0-9]|[1-5][0-9]|100)$/;

  const match = value.match(timeRegex);

  if (!match) return null;

  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);

  return new Date(1970, 0, 1, hours, minutes, 0, 0);
}

// Other
//======================================================================================================================

export function daysDiff(d1: Date, d2: Date): number {
  const t1 = startOfDay(d1).getTime();
  const t2 = startOfDay(d2).getTime();

  return Math.round((t2 - t1) / DAY_IN_MILLIS);
}

export function yearsSinceExistence(): number[] {
  return range(START_OF_EXISTENCE, new Date().getFullYear() + 1).reverse();
}

export function yearsSinceExistenceOptions() {
  return yearsSinceExistence().map((year) => ({
    label: `${year}`,
    value: year,
  }));
}

export function combineDateAndTime(date: Date, time: Date): Date {
  const dateTime = new Date(date);
  dateTime.setHours(time.getHours());
  dateTime.setMinutes(time.getMinutes());

  return dateTime;
}

// Determine the format of manual date input standard: 'DMY'
export function getDateFormat(locale: string) {
  const fallback = ['D', 'M', 'Y'];

  try {
    const formatter = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    const parts = formatter.formatToParts(new Date());
    const filteredParts = parts.filter(({ type }) => ['day', 'month', 'year'].includes(type));

    if (filteredParts.length !== 3) return fallback;

    return filteredParts.map(({ type }) => type.charAt(0).toUpperCase());
  } catch (error) {
    return fallback;
  }
}

export function getUserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
